<template>
  <spinner-container :loading="localLoading">
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <v-card class="card--shadow">
            <supervision-form
              :supervisionData="supervisionEachData"
              @emitData="updateSuperVision"
            />
            <feedback
              :authorId="admin.id"
              :feedbacks="feedbacks"
              @createComment="createComment"
              @deleteComment="deleteComment"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import SupervisionForm from "@/components/supervision/SupervisionForm";
import Feedback from "@/components/feedback/Feedback";
import { mapState } from "vuex";
import BackBtn from "@/components/navigation/BackBtn.vue";
import SpinnerContainer from "../../../components/container/SpinnerContainer.vue";

export default {
  components: {
    SupervisionForm,
    BackBtn,
    SpinnerContainer,
    Feedback,
  },
  computed: {
    ...mapState("SuperVision", ["supervisionEachData"]),
    ...mapState("Loading", ["localLoading"]),
    ...mapState("Feedback", ["feedbacks"]),
    ...mapState("Admin", ["admin"]),
  },
  methods: {
    createComment(comment) {
      const newComment = {
        item_id: this.$route.params.id,
        item_type: "SUPERVISION",
        author_id: this.admin.id,
        author_type: "PA",
        body: comment,
      };
      this.$store.dispatch("Feedback/create", newComment);
    },
    deleteComment(id) {
      this.$store.dispatch("Feedback/delete", id);
    },
    updateSuperVision(data) {
      data.id = this.$route.params.id;
      this.$store.dispatch("SuperVision/update", data).then(() => {
        this.$router.push(
          `/supervision-visit/activity/${this.$route.params.month}/${this.$route.params.year}`
        );
      });
    },
  },
  created() {
    this.$store.dispatch("Loading/changeLocalLoading");
    this.$store.dispatch("Feedback/getAll", {
      item_id: this.$route.params.id,
      item_type: "SUPERVISION",
    });
    this.$store
      .dispatch("SuperVision/getEach", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("Loading/changeLocalLoading");
      });
  },
};
</script>
